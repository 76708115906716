import React from 'react'
import { graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Title from '../components/Title'
import Content from '../components/Content'
import Nav from '../components/Nav'

const Main = styled.div`
  display: flex;
  margin-left: 0;
  align-items: flex-start;

  a:hover {
    /* font-weight: bold; */
    color: #9c9c9c;
  }

  @media (max-width: 768px) {
    display: block;
  }
`

const Meta = styled.div`
  display: none;

  margin-bottom: 3.75rem;

  @media (max-width: 375px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 640px) {
    margin-bottom: 4rem;
  }

  @media (max-width: 1024px) {
    display: block;
  }

  li {
    @media (max-width: 420px) {
      font-size: 17px;

      &:first-of-type {
        margin-right: 28px;
      }
    }

    @media (max-width: 375px) {
      font-size: 16px;
    }
  }
`

const Address = styled.div`
  flex: 1;
  padding-left: 0;
  display: flex;
  max-width: 50%;

  @media (max-width: 1160px) {
    flex-direction: column;
    width: 300px;
    max-width: 100%;
    flex: none;
  }
`

const AddressLeft = styled.div`
  padding-left: 0;
  flex: 1;
`

const AddressRight = styled.div`
  flex: 1;
  margin-left: -32px;

  @media (max-width: 1160px) {
    margin-left: 0;
    margin-top: 1rem;
  }
`

const Hours = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 980px) {
    display: block;
  }

  @media (max-width: 768px) {
    margin-top: 4rem;
  }

  @media (max-width: 375px) {
    margin-top: 3rem;
  }
`

const HoursLeft = styled.div`
  flex: 1;
  padding-left: 32px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`

const HoursRight = styled.div`
  flex: 1;
  padding-left: 32px;

  @media (max-width: 768px) {
    padding-left: 0;
  }
`

const HoursSpacer = styled.div`
  @media (max-width: 980px) {
    display: none;
  }
`

const Item = styled.div`
  border-top: 1px solid ${props => props.theme.colors.primary};
  padding-top: 6px;
  margin-top: 9px;

  &:last-of-type {
    padding-bottom: 9px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
  }

  ${props =>
    props.mobile &&
    css`
      @media (max-width: 980px) {
        margin-top: 0;
        border-top: 0;
      }
    `}
`

const Footer = styled.footer`
  font-size: 12px;
  margin-top: 3rem;

  @media (min-width: 1161px) {
    /* margin-top: -2.7rem; */
  }

  a:hover {
    /* font-weight: bold; */
    color: #9c9c9c;
  }
`

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Zahnarztpraxis" />
    <Hero image={data.heroImage2} />
    <Content id="content">
      <Meta>
        <Nav />
      </Meta>
      <Main>
        <Address>
          <AddressLeft>
            Zahnarztpraxis Breitenrain
            <br />
            Breitenrainplatz 36
            <br />
            3014 Bern
          </AddressLeft>
          <AddressRight>
            Telefon <a href="tel:031 332 99 22">031 332 99 22</a>
            <br />
            <a href="mailto:praxis@zahnarztbreitenrain.ch">
              praxis@zahnarztbreitenrain.ch
            </a>
            <br />
            <a href="https://www.zahnarztbreitenrain.ch">
              www.zahnarztbreitenrain.ch
            </a>
          </AddressRight>
        </Address>
        <Hours>
          <HoursLeft>
            <Title as="h2">ÖFFNUNGSZEITEN</Title>
            <Item>
              Montag
              <br />
              7.30—18.30 Uhr
            </Item>
            <Item>
              Dienstag und Donnerstag <br />
              7.30—17.00 Uhr
            </Item>
          </HoursLeft>
          <HoursRight>
            <HoursSpacer>
              <Title as="h2">&nbsp;</Title>
            </HoursSpacer>
            <Item mobile>
              Mittwoch und Freitag <br />
              7.30—16.00 Uhr
            </Item>
            <Item>
              Samstag einmal monatlich <br />
              8.00—12.00 Uhr
            </Item>
          </HoursRight>
        </Hours>
      </Main>
      <Footer>
        <Link to="/impressum">Impressum</Link>
      </Footer>
    </Content>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    heroImage2: file(
      relativePath: { eq: "zahnarztbreitenrain_team_2022_rgb.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
