import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'

import arrowDownImg from '../images/arrow-down.svg'

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  max-height: 630px;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    margin-bottom: 2.25rem;
  }

  @media (max-width: 640px) {
    margin-bottom: 1.5rem;
  }
`

const StyledImg = styled(Img)`
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadein 2s;
  min-height: 212px;
`

const Scroll = styled.a`
  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(-50%, 0, 0);
    }
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(-50%, -30px, 0);
    }
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(-50%, -15px, 0);
    }
    90% {
      transform: translate3d(-50%, -4px, 0);
    }
  }

  @keyframes bounce-small {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(-50%, 0, 0);
    }
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(-50%, -20px, 0);
    }
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(-50%, -10px, 0);
    }
    90% {
      transform: translate3d(-50%, -2px, 0);
    }
  }

  padding: 20px;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  animation-duration: 3s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  transform-origin: center bottom;
  animation-name: bounce;
  opacity: 0;
  transition: opacity 2s ease;
  pointer-events: none;
  cursor: default;

  ${props =>
    props.isActive &&
    css`
      opacity: 1;
      pointer-events: inherit;
      cursor: pointer;
    `}

  @media (max-width: 580px) {
    bottom: 5px;
    animation-name: bounce-small;
  }
`

const ArrowDown = styled.img`
  display: block;
  width: 46px;
  height: auto;

  /* IE 11 hack */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: 25px;
  }

  @media (max-width: 580px) {
    width: 34px;
  }
`

const Hero = ({ image }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      const hasScroll = document.body.scrollHeight > window.innerHeight
      if (hasScroll) {
        setShow(true)
      }
    }, 1000)
  }, [])

  return (
    <Wrapper id="hero">
      <Scroll href="#content" data-scroll isActive={show}>
        <ArrowDown src={arrowDownImg} alt="" />
      </Scroll>
      <StyledImg
        fluid={image.childImageSharp.fluid}
        durationFadeIn={0}
        fadeIn={false}
        objectPosition={`center top`}
      />
    </Wrapper>
  )
}

export default Hero
